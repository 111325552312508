import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "HTTP Status Codes",
  "slug": "http-status-codes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`HTTP Status Codes`}</h1>
    <p>{`enableHR REST APIs use the Status-Line part of an HTTP response message to inform clients of their request’s overarching result.`}</p>
    <p>{`RFC 2616 defines the Status-Line syntax as shown below:`}</p>
    <p><inlineCode parentName="p">{`Status-Line = HTTP-Version SP Status-Code SP Reason-Phrase CRLF`}</inlineCode></p>
    <p>{`HTTP defines these standard status codes that can be used to convey the results of a client’s request. The status codes are divided into the five categories.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informational – Communicates transfer protocol-level information.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success – Indicates that the client’s request was accepted successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client Error – This category of error status codes points the finger at clients.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server Error – The server takes responsibility for these error status codes.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      